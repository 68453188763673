import React, { useState, useEffect } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { defineReactNode } from "./noodl-sdk";
import { OrangeDAONodes } from "./OrangeDAONodes";
import { OrangeDAOTheme } from "./OrangeDAOTheme";
import EmojiPickerPlugin from "./plugins/EmojiPickerPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { ErrorBoundary } from "./ui/ErrorBoundry";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { MentionsPlugin } from "./plugins/MentionsPlugin";
import { ToolbarPlugin } from "./plugins/ToolbarPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import LinkPlugin from "./plugins/LinkPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import { CLEAR_EDITOR_COMMAND } from "lexical";
import ImagesPlugin from "./plugins/ImagesPlugin";
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
import DragDropPaste from "./plugins/DragDropPaste";
import FloatingLinkEditorPlugin from "./plugins/FloatingLinkEditorPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";

const emptyEditorState =
  '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

const convertTextEditorState = (text) =>
  `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"${text}","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`;

function Editor(props) {
  const [editor] = useLexicalComposerContext();
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
  props.registerListeners(() => {
    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
  });
  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    if (editor) {
      props.onDidMount(editor);
      editor.registerUpdateListener(({ editorState }) => {
        const mentioned = [];
        const images = [];
        for (let [key, value] of editorState._nodeMap) {
          switch (value.__type) {
            case "mention":
              mentioned.push(value.__mention.key);
              break;
          }
        }
        props.onContentChanged(
          editorState.toJSON(),
          mentioned,
          editorState._nodeMap.get("root").__cachedText,
          images
        );
        if (images.length === 0) {
          props.SaveContent(
            editorState.toJSON(),
            mentioned,)
        }
      });
    }
  }, [editor]);

  return (
    <div className={props.readOnly ? "display-shell" : "editor-shell"}>
      {!props.readOnly && <ToolbarPlugin />}
      <div
        className="editor-container"
        onClick={() => {
          // editor.focus(() => {
          //   const activeElement = document.activeElement;
          //   const rootElement = editor.getRootElement()
          //   if (rootElement !== null && (activeElement === null || !rootElement.contains(activeElement))) {
          //     rootElement.focus({ preventScroll: true })
          //   }
          // }, {
          //   defaultSelection: 'rootEnd'
          // })
        }}
      >
        <EmojiPickerPlugin emojis={props.emojis || []} />
        <ClearEditorPlugin />
        <DragDropPaste />
        <HistoryPlugin />
        <ListPlugin />
        <CheckListPlugin />
        <LinkPlugin />
        <CodeHighlightPlugin />
        <AutoLinkPlugin />
        <ImagesPlugin />
        <MentionsPlugin mentionables={props.menitionables || []} />
        {
          floatingAnchorElem && (
            <>
              {/* <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} /> */}
              <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
            </>
          )
        }
        <RichTextPlugin
          contentEditable={
            props.readOnly ? (
              <div className="lexical-display" ref={onRef}>
                <ContentEditable />
              </div>
            ) : (
              <div className="editor-scroller">
                <div className="editor" ref={onRef}>
                  <ContentEditable className={"ContentEditable__root"} />
                </div>
              </div>
            )
          }
          placeholder={
            <div className="editor-placeholder">{props.placeholder}</div>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
      </div>
    </div>
  );
}

function LexicalEditor(props) {
  let isLexical = true;
  let state = emptyEditorState;
  if (props.editorState) {
    try {
      JSON.parse(props.editorState);
      state = props.editorState;
    } catch (e) {
      isLexical = false;
      props.onContentChanged({}, [], props.editorState, []);
    }
  }
  const initialState = {
    editable: !props.readOnly,
    editorState: state,
    nodes: OrangeDAONodes,
    onError(error) {
      console.error(error);
    },
    theme: OrangeDAOTheme,
  };

  return isLexical ? (
    <ErrorBoundary fallback={<div>{props.editorState}</div>}>
      <LexicalComposer initialConfig={initialState}>
        <Editor {...props} />
      </LexicalComposer>
    </ErrorBoundary>
  ) : (
    <div className="display-shell">
      <div className="editor-container">
        <div className="lexical-display">{props.editorState}</div>
      </div>
    </div>
  );
}

export const LexicalEditorNode = defineReactNode({
  name: "Lexical Editor",
  category: "OrangeDAO",
  getReactComponent() {
    return LexicalEditor;
  },
  initialize() {
    this.props.onDidMount = (domElement) => {
      this.setOutputs({ editorRef: domElement });
      this.sendSignalOnOutput("editorSet");
    };
    this.props.onContentChanged = (content, mentioned, plainText, images) => {
      this.setOutputs({
        currentState: content,
        mentioned: mentioned,
        currentPlainTextState: plainText,
        images
      });
    };
    this.props.registerListeners = (func) => {
      this.listeners = [func];
    };
    this.props.ClearContent = () => {
      this.listeners.forEach((l) => l());
    };
    this.props.registerImageListener = (func) => {
      this.imageListner = [func];
    };
    this.props.InsertImage = (imageInfo) => {
      this.imageListner.forEach((l) => l(imageInfo));
    };
    this.props.SaveContent = (content, mentioned) => {
      this.setOutputs({
        currentState: content,
        mentioned: mentioned,
        images: [],
      });
      this.sendSignalOnOutput("contentReadyToSave");
    };
  },
  signals: {
    clear() {
      this.props.ClearContent();
    },
    save() {
      
    },
    updateImage() {

    }
  },
  inputProps: {
    editorState: {
      type: "object",
      displayName: "Initial State",
    },
    readOnly: {
      type: "boolean",
      displayName: "Read-Only?",
    },
    placeholder: {
      type: "string",
      displayName: "Placeholder",
    },
    menitionables: {
      type: "array",
      displayName: "Mentionables",
    },
    emojis: {
      type: "array",
      displayName: "Emojis",
    },
    imageToUpdate: {
      type: "object",
      displayName: "Image URL",
    },
    uploadFunction: {
      type: "object",
      displayName: "Image Upload Function",
    },
  },
  outputs: {
    editorRef: {
      type: "object",
      displayName: "Editor Ref",
    },
    activeEditorRef: {
      type: "object",
      displayName: "Active Editor Ref",
    },
    editorSet: {
      type: "signal",
      displayName: "Ref ready",
    },
    currentState: {
      displayName: "Current State",
      type: "object",
    },
    currentPlainTextState: {
      displayName: "Current Plain Text",
      type: "string",
    },
    contentChanged: {
      type: "signal",
      displayName: "Content Changed",
    },
    mentioned: {
      type: "array",
      displayName: "Mentioned",
    },
    images: {
      type: "array",
      displayName: "Images",
    },
    contentReadyToSave: {
      type: "signal",
      displayName: "Ready to Save",
    },
  },
});
